<!-- eslint-disable -->
<template>
</template>
<!-- eslint-enable -->
<script>
import Vue from 'vue';

export default {
  created() {
      var url = "/MobileLogin"
      if (Vue.prototype.isSys4Conjunto()) {
        url += '/' + localStorage.getItem('instalacion')
      }
      //desconectamos el usario para la concurrencia
      this.$socket.emit('desconectar', this.$store.getters.getSesion)
      clearInterval(this.intervalMinutosTrabajados)
      sessionStorage.clear()
      localStorage.clear()
      this.$store.reset()
      this.$store.commit('logout')
      this.$router.push(url)    
  },
};
</script>