<template>
  <v-app class="link">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content transparent pb-3 mt-1" style="height: 56px;" background-color="transparent">
        <v-row class="d-flex align-center">
            <div elevation="0" class="pl-0 header-icon">
              <v-icon class="icon link"></v-icon>
            </div>
        </v-row>
      </div>
    </header>
        <Menu/>
  <v-main class="margin-top border-list">
    <v-list class="pb-0">
      <v-list-item-group
        color="primary"
      > 
    <v-list-item
        v-for="file in files"
        :key="file.title"
      >
        <v-list-item-content>
          <v-list-item-title class="primary--text text-subtitle-1 mb-0  text-uppercase" v-text="file.title"></v-list-item-title>
          <v-list-item-subtitle class="primary--accent" v-text="file.url"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon text>
            
            <v-icon 
              dark
              > mdi-link</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
          <v-divider></v-divider>
      </v-list-item-group>
    </v-list>
    
    </v-main>   
  </v-app>
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'


  export default Vue.extend({
    name: 'Links',
     data: () => ({
     files: [
        { 
          title: 'Título link',
          url: 'www.yet.com',

        },
           { 
          title: 'Título link',
          url: 'wwww.yet.com',

        },
           { 
          title: 'Título link',
          url: 'www.yet.com',

        },
           { 
          title: 'Título link',
          url: 'wwww.yet.com',

        },
           { 
          title: 'Título link',
          url: 'www.yet.com',

        },
           { 
          title: 'Título link',
          url: 'wwww.yet.com',

        },
           { 
          title: 'Título link',
          url: 'www.yet.com',

        },
           { 
          title: 'Título link',
          url: 'wwww.yet.com',

        },
           { 
          title: 'Título link',
          url: 'www.yet.com',

        },
           { 
          title: 'Título link',
          url: 'wwww.yet.com',

        },
      ],
    }),
    components: {
      Menu,
    },
  })
</script>
