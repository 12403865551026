<template>
  <v-app class="calendar">

        <v-app-bar elevation="0" class="pl-16" color="white">
           <Header/>
        </v-app-bar>
        <Menu />
        <v-main class="">
        <template>
          <div class="mt-15">
            <v-sheet
              tile
              height="54"
              class="d-flex"
            >
              <v-btn
                icon
                class="ma-2"
                @click="mesAnterior()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-layout justify-center>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Hoy
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar" class="text-capitalize primary--text">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
              </v-layout>
              <v-btn
                icon
                class="ma-2"
                @click="mesSiguiente()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet height="auto">
              <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                type="month"
                :events="events"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
                @change="cambiarFecha"
                @click:event="showEvent"
                locale="es"
                 color="primary"
              ></v-calendar>
            </v-sheet>
          </div>
        </template>
        <div class="text-center ml-auto">
          <v-dialog
            transition="dialog-top-transition"
            max-width="600"
            class="rounded-0"
          
            ref
            v-model="dialog"
          >
            <template v-slot:default="dialog">
              <v-card class="">
                <v-card-text class="d-flex px-0 pb-0 flex-column">
                  <v-row class="col-12 pa-0 ma-0">
                    <v-col class="d-flex py-0 align-center justify-space-between">
                      <h3 class="text-uppercase primary--text text-center py-3">
                        <v-icon class="icon lista ">
                        </v-icon>
                        Detalle de fichaje
                      </h3>
                     
                      <v-btn
                        class="ml-4 accent--text"
                        icon
                        top
                        right
                        @click="dialog.value = false"
                      ><v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="col-12 pa-0 ma-0">
                    <v-col>
                      <p class="text-h6 text--primary">
                        Total: {{ totalHoras }}
                      </p>
                      <p class="text--primary">Horario: {{ horario }}</p>
                      <div class="text--primary">
                          Tipo: {{ tipoJor }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="col-12 pa-0 ma-0"> 
                    <v-col>
                      <table id="tablaFichaje" class='mt-4' cellspacing='0' cellpadding='0' border=0 width='100%'>
                        <tr>
                          <th scope='row'>Tipo</th>
                          <th scope='row'>Hora</th>
                          <th scope='row'>Obsv</th>
                        </tr>
                        <tr v-for='ficha in fichajeSelec' :key='ficha.msfichajeid'> 
                          <td>
                              {{ ficha.msfichajetipostr }}
                          </td>
                          <td >
                              {{ ficha.msfichajehora }}
                          </td>
                          <td>
                              {{ ficha.msfichajeobs }}
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>
        </div>
      </v-main>  
    </v-app>
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'
  import Header from '../components/Calendar/CHeader.vue'

  import { CalenlabApiService } from '../api/CalenlabApiService'
  import { FichajeApiService } from '../api/FichajeApiService'
  import { JornadaApiService } from '../api/JornadaApiService'
  import { HorariodiaApiService } from '../api/HorariodiaApiService'
  import { Util } from '../api/Util'
  import { VacacionApiService } from '../api/VacacionApiService'

  const vacacionApiService = new VacacionApiService()
  const calenlabApiService = new CalenlabApiService()
  const fichajeApiService = new FichajeApiService()
  const jornadaApiService = new JornadaApiService()
  const horariodiaApiService = new HorariodiaApiService()
  const util = new Util()
  const moment = require('moment')

  export default Vue.extend({
    name: 'Calendar',
    components: {
      Menu,
      Header,
    },
    data() {
      return {
        dialog: false,
        weekday: [1, 2, 3, 4, 5, 6, 0],
        value: moment().format('YYYY-MM-DD'),
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        today: moment(),
        dateContext: moment(),
        lang: sessionStorage.getItem('lang'),
        calendarioLaboral: [],
        fichaje: [],
        opuid: '',
        duracionJornada: 25200000,
        personaJornada: [],
        horariodia: [],
        vacacion: [],
        totalHoras: '',
        horario: '',
        tipoJor: '',
        fichajeSelec: []
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      year: function () {
        return this.dateContext.format('Y')
      },
      mesSimple: function () {
        return this.dateContext.format('M')
      },
      month: function () {
        return this.dateContext.format('MMMM')
      },
      mes: function () {
        return this.dateContext.format('MM')
      },
    },
    watch: {
      year: {
        handler (n, o) {
          if (n !== o) {
            // Cargamos el calendario laboral de ese anho
            // this.getDiatrabajoByAnhoAndOpuid()
          }
        },
        deep: true
      },
      month: {
        handler (n, o) {
          if (n !== o) {
            this.events = []           
            this.getPersonajornadaByFechaAndPersonaUid()
            this.getVacacionByMonthYearAndPersonaId()
            this.getDiatrabajoByAnhoAndOpuid()
            // this.getFichajeByAnhoMesAndPersonaId()
          }
        },
        deep: true
      },
    },
    methods: {
      init () {
        this.opuid = this.$store.getters.getOpuid
        this.getDiatrabajoByAnhoAndOpuid()
        this.getPersonajornadaByFechaAndPersonaUid()
        this.getVacacionByMonthYearAndPersonaId()
        // this.getFichajeByAnhoMesAndPersonaId()
        // this.agregarEventos()
      },
      agregarEventos () {
        let dia, color, diaN = ''
        this.fichaje.forEach(element => {
          if (diaN !== moment(element.msfichajefecha).format('DD')) {
            diaN = moment(element.msfichajefecha).format('DD')
            dia = this.checkDia(moment(element.msfichajefecha).format('DD'), moment(element.msfichajefecha).format('MMM'), moment(element.msfichajefecha).format('YYYY'))
            if (dia.jornadaCorrecta) {
              color = 'green'
            } else {
              color = 'orange'
            }
            if (dia.duracionMs !==0) {
              this.events.push({
                name: dia.duracionHoras,
                start: new Date(element.msfichajefecha),
                end: new Date(element.msfichajefecha),
                color: color,
                timed: false,
                dia: dia
              })
            }
            if (dia.diaTipo === 4) {
              this.events.push({
                name: 'Festivo',
                start: new Date(element.msfichajefecha),
                end: new Date(element.msfichajefecha),
                color: 'indigo',
                timed: false,
              })
            }
          }
        });
      },
      getDiatrabajoByAnhoAndOpuid () {
        if (this.year && this.opuid) {
          calenlabApiService.getDiatrabajoByAnhoAndOpuid(this.year, this.opuid)
            .then(data => {
              this.calendarioLaboral = data
              this.calendarioLaboral.forEach(element => {
                 this.events.push({
                  name: 'Festivo',
                  start: new Date(element.msdiatrabajofecha),
                  end: new Date(element.msdiatrabajofecha),
                  color: 'indigo',
                  timed: false,
                })
              });
            })
            .catch(error => {
             console.log('PersonasTabFichajes - getDiatrabajoByAnhoAndOpuid', error, 'ERROR')
            })
        }
      },
      getFichajeByAnhoMesAndPersonaId () {
        if (this.year) {
          fichajeApiService.getFichajeByAnhoMesAndPersonaId(this.year, this.mesSimple, this.$store.state.userInfo.id)
            .then(data => {
              this.fichaje = data
              this.agregarEventos()
            })
            .catch(error => {
              util.mostrarEnConsola('PersonasTabFichajes - getFichajeByAnhoMesAndPersonaId', error, 'ERROR')
            })
        }
      },
      getPersonajornadaByFechaAndPersonaUid () {
        jornadaApiService.getPersonajornadaByFechaAndPersonaUid(moment(this.dateContext).format('YYYY-MM-DD'), this.$store.state.userInfo.datos.mspersonauid)
          .then(data => {
            this.personaJornada = data
            this.getHorariodiaByJornadaId()
          })
          .catch(error => {
            this.getFichajeByAnhoMesAndPersonaId()
            util.mostrarEnConsola('PersonasTabFichajes - getPersonajornadaByFechaAndPersonaUid', error, 'ERROR')
          })
        
      },
      getHorariodiaByJornadaId () {
        if (this.personaJornada.length > 0) {
          horariodiaApiService.findAllHorariodia({ jornada: { msjornadaid: this.personaJornada[0].jornada.msjornadaid } })
            .then(data => {
              // Recorremos el array, sumamos totales y ponemos par
              let count = 0
              for (let i = 0; i < data.length; i++) {
                let horariodiaItem = data[i]
                let duracion = 0
                if (horariodiaItem.mshorariodiafin1 && horariodiaItem.mshorariodiainicio1) {
                  duracion += moment.duration(moment(horariodiaItem.mshorariodiafin1, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio1, 'hh:mm')))
                }
                if (horariodiaItem.mshorariodiafin2 && horariodiaItem.mshorariodiainicio2) {
                  duracion += moment.duration(moment(horariodiaItem.mshorariodiafin2, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio2, 'hh:mm')))
                }
                horariodiaItem.total = util.convertirMilisegundoAHoras(duracion, 1)
                if (count === 0) {
                  horariodiaItem.diaPar = true
                  count++
                } else {
                  horariodiaItem.diaPar = false
                  count = 0
                }
              }
              this.horariodia = data
            })
            .catch(error => {
              
              util.mostrarEnConsola('PersonasTabFichajes - getHorariodiaByJornadaId', error, 'ERROR')
            })
        } 
        this.getFichajeByAnhoMesAndPersonaId()
        
      },
      checkDia: function (dia, mes, anho) {
        var diaObj = {
          diaNumber: null,
          diaStr: '',
          diaTipo: 0,
          duracionMs: 0,
          duracionHoras: '0:00',
          jornadaCorrecta: false,
          horario: '',
          duracionJornada: '',
          tipoJornada: '',
          hoy: false,
          fichaje: '',
        }
        if (dia && mes && anho) {
          var fecha = moment(dia + '-' + mes + '-' + anho, 'DD-MMM-YYYY')
          // if (this.isFestivo(fecha)) { // Comprobamos si es festivo
          //   diaObj.diaNumber = dia
          //   diaObj.diaStr = String(dia)
          //   diaObj.diaTipo = 4
          // }
          // else if (moment(fecha).day() % 6 === 0) { // Fin de semana
          //   diaObj.diaNumber = dia
          //   diaObj.diaStr = String(dia)
          //   diaObj.diaTipo = 2
          // } else { // Todo lo demas
          //   diaObj.diaNumber = dia
          //   diaObj.diaStr = String(dia)
          //   diaObj.diaTipo = 1
          // }
          if (moment(fecha).isSame(new Date(), 'day')) { // Comprobamos si es hoy
            diaObj.hoy = true
          }
          // Comprobamos el fichaje
          // var duracion = this.getDuracionFichaje(fecha)
          var fichaje = this.getFichaje(fecha)
          var duracion = this.getDuracionFichaje(fichaje)

          diaObj.duracionMs = duracion.duracionMs
          diaObj.duracionHoras = duracion.duracionHoras
          diaObj.fichaje = fichaje
          // Obtenemos la duracion de la jornada
          let duracionJor = 0
          if (this.horariodia.length) {
            for (let i = 0; i < this.horariodia.length; i++) {
              const horariodiaItem = this.horariodia[i]
              if (horariodiaItem.mshorariodiadiasemana === fecha.day()) {
                if (horariodiaItem.mshorariodiafin1 && horariodiaItem.mshorariodiainicio1) {
                  duracionJor += moment.duration(moment(horariodiaItem.mshorariodiafin1, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio1, 'hh:mm')))
                }
                if (horariodiaItem.mshorariodiafin2 && horariodiaItem.mshorariodiainicio2) {
                  duracionJor += moment.duration(moment(horariodiaItem.mshorariodiafin2, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio2, 'hh:mm')))
                }
                diaObj.duracionJornada = util.convertirMilisegundoAHoras(duracionJor, 2)
                diaObj.horario = horariodiaItem.mshorariodiainicio1 + '-' + horariodiaItem.mshorariodiafin1 
                if (horariodiaItem.mshorariodiainicio2 !== null && horariodiaItem.mshorariodiafin2 !== null)
                  diaObj.horario += ' / ' + horariodiaItem.mshorariodiainicio2 + '-' + horariodiaItem.mshorariodiafin2
                diaObj.tipoJornada = horariodiaItem.jornada.msjornadadesc
              }
            }
          }
          if (diaObj.duracionMs > duracionJor) {
            diaObj.jornadaCorrecta = true
          } else {
            diaObj.jornadaCorrecta = false
          }
        }
        return diaObj
      },
      getFichaje (fecha) {
        let fichajeAux = []
        if (this.fichaje.length > 0) {
          for (let i = 0; i < this.fichaje.length; i++) {
            if (moment(fecha).isSame(this.fichaje[i].msfichajefecha)) {
              fichajeAux.push(this.fichaje[i])
            }
          }
        }
        return fichajeAux
      },
      getDuracionFichaje (fichaje) {
        var total = {duracionMs: 0, duracionHoras: '0:00'}
        var duracion = 0
        var count = 0
        var horaAux = 0
        if (fichaje.length > 0) {
          for (let i = 0; i < fichaje.length; i++) {
            const fichajeItem = fichaje[i]
            if ((fichajeItem.msfichajetipo !== 2 && fichajeItem.msfichajetipo !== 3) || i!==0) {
              if (count === 0) { // Guardamos la primera hora
                horaAux = fichajeItem.msfichajehora
                count++
              } else { // Obtenemos la duracion del intervalo en ms
                duracion += moment.duration(moment(fichajeItem.msfichajehora, 'hh:mm').diff(moment(horaAux, 'hh:mm')))
                count = 0
              }
            }
          }
        }
        if (duracion !== 0) {
          total.duracionMs = duracion
          total.duracionHoras = util.convertirMilisegundoAHoras(duracion, 2)
        }
        return total
      },
      isFestivo (fecha) {
        if (this.calendarioLaboral.length > 0) {
          for (let i = 0; i < this.calendarioLaboral.length; i++) {
            const calendarioLaboralItem = this.calendarioLaboral[i]
            if (moment(fecha).isSame(calendarioLaboralItem.msdiatrabajofecha)) {
              if (calendarioLaboralItem.msdiatrabajotipo === 1 || calendarioLaboralItem.msdiatrabajotipo === 2 || calendarioLaboralItem.msdiatrabajotipo === 3 || calendarioLaboralItem.msdiatrabajotipo === 4) {
                return true
              }
            }
          }
          return false
        }
      },
      getVacacionByMonthYearAndPersonaId () {
        vacacionApiService.getVacacionByMonthYearAndPersonaId(this.mes, this.year, this.$store.state.userInfo.id)
          .then(data => {
            util.mostrarEnConsola('PersonasTabVacaciones - getVacacionByMonthYearAndPersonaId', data.length, 'OK')
            this.vacacion = data
            this.vacacion.forEach(element => {
              this.events.push({
                name: 'Vacaciones',
                start: new Date(element.msvacacionfecha),
                end: new Date(element.msvacacionfecha),
                color: 'cyan',
                timed: false,
              })
            });
          })
          .catch(error => {
            util.mostrarEnConsola('PersonasTabVacaciones - getVacacionByMonthYearAndPersonaId', error, 'ERROR')
          })
      },
      showEvent ({ nativeEvent, event }) {
        if (event.name !== 'Festivo' && event.name !== 'Vacaciones') {
          this.dialog = true
          this.totalHoras =  event.name 
          this.tipoJor = event.dia.tipoJornada
          this.horario = event.dia.horario
          this.fichajeSelec = event.dia.fichaje
          nativeEvent.stopPropagation()
        }
      },
      // getEvents ({ start, end }) {
      //   const events = []

      //   const min = new Date(`${start.date}T00:00:00`)
      //   const max = new Date(`${end.date}T23:59:59`)
      //   const days = (max.getTime() - min.getTime()) / 86400000
      //   const eventCount = this.rnd(days, days + 20)

      //   for (let i = 0; i < eventCount; i++) {
      //     const allDay = this.rnd(0, 3) === 0
      //     const firstTimestamp = this.rnd(min.getTime(), max.getTime())
      //     const first = new Date(firstTimestamp - (firstTimestamp % 900000))
      //     const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
      //     const second = new Date(first.getTime() + secondTimestamp)

      //     events.push({
      //       name: this.names[this.rnd(0, this.names.length - 1)],
      //       start: first,
      //       end: second,
      //       color: this.colors[this.rnd(0, this.colors.length - 1)],
      //       timed: !allDay,
      //     })
      //   }

      //   this.events = events
      // },
      cambiarFecha () {
        this.dateContext = moment(this.value)
      },
      getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      setToday () {
        this.value = moment().format('YYYY-MM-DD')
      },
      mesAnterior () {
        this.$refs.calendar.prev()
      },
      mesSiguiente () {
        this.$refs.calendar.next()
      }
    },
  })
</script>
<style scoped>

</style>