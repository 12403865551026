<template>
<v-app class="chat">
   <header class="pl-16 v-app-bar white">
      <div class="v-toolbar__content transparent pb-3 mt-1" style="height: 56px;">
        
        <v-row class="d-flex align-center">
            <div elevation="0" class="pl-0 header-icon">
              <v-icon class="icon chat"></v-icon>
            </div>
              <!-- <v-col class="d-flex py-0 align-baseline justify-space-between ">
              <v-text-field class="buscar d-flex pl-6" label="Buscar" ></v-text-field>
            
            <v-btn class="icon search" icon large > </v-btn></v-col> -->
        </v-row>
      </div>
    </header>
    <Menu />
    <v-main class="margin-top">
      <v-card
        max-width="450"
        class="mx-auto"
      >
        <v-list three-line class="elevation-0 pt-0">
          <template v-for="(item, index) in items">
            <!-- <v-subheader
              v-if="item.header"
              :key="item.header"
              v-text="item.header"
            ></v-subheader> -->

            <v-divider
              v-if="index!==0"
              :key="index"
              :inset="item.inset"
            ></v-divider>

            <v-list-item
              :key="item.conversation_id"
              :to="{ name: 'ChatDetail', params: { conversationId: item.conversation_id, userId: item.user_id , agentId: agentId}}"
            >
            <!-- <v-list-item
              v-else
              :key="item.title"
              > -->
              <v-list-item-avatar>
                <v-img :src="item.profile_image"></v-img>
                <div :ref="item.user_id"></div>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-uppercase primary--text font-weight-medium" v-html="item.first_name"></v-list-item-title>
                <v-list-item-subtitle v-show="item.message!=='inicio_conversacion'" v-html="item.message"></v-list-item-subtitle>
                <v-list-item-subtitle v-show="item.message!=='inicio_conversacion'" class="text-right">{{ item.creation_time | chatDate }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
           <v-divider
            v-if="usuarios.length>0"></v-divider>
          <!-- <v-subheader>Otros</v-subheader> -->
          <template v-for="(item, index) in usuarios">
             <v-divider
              v-if="index!==0"
              :key="index + 'd'"
              :inset="item.inset"
            ></v-divider>

            <v-list-item
              :key="index + 'u'"
              :to="{ name: 'ChatDetail', params: { conversationId: '-1', userId: item.id , agentId: agentId}}"
            >
              <v-list-item-avatar>
                <v-img :src="item.profile_image"></v-img>
                <div :ref="item.id"></div>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase primary--text font-weight-medium" v-html="item.first_name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-main> 
     <!-- snackbar para mensajes en pantalla -->
      <div class="text-center">
        <v-snackbar
          v-model="snackbar"
          :multi-line="multiLine"
          color="red lighten-1"
        >
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <!--  --> 
  </v-app>
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'
  import { ChatApiService } from '../api/ChatApiService'
  import { PersonaApiService } from '../api/PersonaApiService'

  const chatApiService = new ChatApiService()
  const personaApiService = new PersonaApiService()

  export default Vue.extend({
    name: 'Profile',
    components: {
      Menu,
    },
     data: () => ({
      multiLine: true,
      snackbar: false,
      agentId: '',
      text: '',
      usuarios: [],
      usuariosOnline: [],
      personas: [],
      items: [
        // { header: 'Hoy' },
        // {
          
        //   avatar: require("@/assets/images/6.jpg"),
        //   title: 'Tienda 104',
        //   subtitle: `<span class="text--primary">17:00</span> &mdash; Mañana llega el pedido nº570`,
        // },
        // { divider: true, inset: true },
        // {
        //   avatar: require("@/assets/images/5.jpg"),
        //   title: 'Ana García',
        //   subtitle: `<span class="text--primary">16:10</span> &mdash; Tengo que salir al médico, llama a Ester para que venga a mi puesto`,
        // },
        // { divider: true, inset: true },
        // {
        //   avatar: require("@/assets/images/3.jpg"),
        //   title: 'Ester Martínez',
        //   subtitle: '<span class="text--primary">15:23</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        // },
        // { divider: true, inset: true },
 
        // {
        //   avatar: require("@/assets/images/4.jpg"),
        //   title: 'María Rioja',
        //   subtitle: '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        // },
        //   { divider: true, inset: true },
        //  { header: 'Ayer' },
        //    {
        //   avatar: require("@/assets/images/2.jpg"),
        //   title: 'Aitor Hernández',
        //   subtitle: '<span class="text--primary">10:30</span> &mdash; Los artículos de la nueva colección están colocados en la tiemda',
        // },
        //   {
          
        //   avatar: require("@/assets/images/7.jpg"),
        //   title: 'Tienda 102',
        //   subtitle: `<span class="text--primary">11:55</span> &mdash; Recordad que hay que hacer inventario del stock de verano`,
        // },
        // { divider: true, inset: true },
        //  { header: '16/07/2021' },
        // {
        //   avatar: require("@/assets/images/1.jpg"),
        //   title: 'Carlos Rodríguez',
        //   subtitle: '<span class="text--primary">10:01</span> Hoy es mi cumpleaños, he dejado unas pastas en el almacén',
        // },
        
      ],
    }),
    created () {
      this.agentId = this.$store.getters.getChatAgentId
      // this.loginChat()
      this.init()
      this.intervalAgentConversations = setInterval(() => this.getAgentConversations(), 1000)
      this.intervalAgentsOnline = setInterval(() => this.getAgentsOnline(), 1000)
      // this.interval3 = setInterval(() => this.updateUserLastActivity(), 1000);
    },
    destroyed() {
      clearInterval(this.intervalAgentConversations)
      clearInterval(this.intervalAgentsOnline)
      // clearInterval(this.interval3)
    },
    methods: {
      init() {
        this.getAgents()
        this.getAgentConversations()
      },
      /**
       * recogemos las conversaciones con el último mensajo y sus agentes asociados
       */
      getAgentConversations(){
        chatApiService.getAgentConversations(this.agentId)
        .then((data) => {
          // console.log(data)
          if (data[1].length > 0) {
            // quitamos de los agentes en general los que tienen conversacion
            data[1].forEach((element) => {
              this.usuarios = this.usuarios.filter(item => item.id !== element.user_id)
              if (this.personas.length > 0) {
                var persona = this.personas.filter(item => item.mspersonachatid == element.user_id)
                // Actualizamos las imágenes
                if (persona.length>0) {
                  element.profile_image = 'data:image/png;base64, ' + persona[0].mspersonaimagen
                } else {
                  element.profile_image = 'https://tienda.yet.es/supportboard/media/user.svg'
                }
              } else {
                 element.profile_image = 'https://tienda.yet.es/supportboard/media/user.svg'
              }
            });
            this.items = data[1] 
          } else {
            // this.snackbar = true;
            // this.text = 'No hay conversaciones'
          }
        })
        .catch((error) =>{
          this.snackbar = true;
          this.text = error
        })
      },
      getNewConversations(){
         chatApiService.getNewConversations()
        .then((data) => {
          // console.log(data)
          if (data[1].length > 0) {
            // console.log(data[1])
            this.items = data[1]
          } else {
            // this.snackbar = true;
            // this.text = 'No hay conversaciones'
          }
        })
        .catch((error) =>{
          this.snackbar = true;
          this.text = error
        })

      },
      /**
       * recogemos todos los agentes
       */
      getAgents() {
        chatApiService.getAgents()
          .then(data => {
            this.usuarios = data[1].filter(item => item.id !== this.agentId)
            // quitamos con los que tenemos conversaciones
            if (this.items.length > 0) {
              this.items.forEach(element => {
                this.usuarios = this.usuarios.filter(item => item.id !== element.user_id)
              });
            }
            this.getPersonas()
          })
          .catch(error => {
            console.log(error)
            this.snackbar = true;
            this.text = 'Error get usuarios'
          })
      },
      getAgentsOnline() {
        chatApiService.getAgentsOnline(this.agentId)
         .then(data => {
            // console.log('online')
            // console.log(data[1])
            if (data[1].length > 0) {
              this.usuariosOnline = data[1]
              for (var ref in this.$refs) {
                 if (this.usuariosOnline.some(element => element.id == ref)) {
                   this.$refs[ref][0].classList.value = 'is-online'
                 } else {
                   this.$refs[ref][0].classList.value = 'is-offline'
                 }
              }
                // this.usuariosOnline.some(element => element.id = id)
            } 
         })
         .catch(error => {
           console.log(error)
           this.snackbar = true;
           this.text = 'Error agentes en linea'
         })
      },
      updateUserLastActivity() {
        chatApiService.updateUserLastActivity(this.agentId)
          .then(() => {
            // console.log('activity')
            // console.log(ddata)
          })
          .catch(error => {
            console.log(error)
            this.snackbar = true;
            this.text = 'Error actualizar activity'
          })
      },
      getPersonas() {
        let listaIdsInConv = this.items.map(function(item) {
          return item.user_id
        })
        let listaIdsNotInConv = this.usuarios.map(function(item) {
          return item.id
        })
        let listaIds = listaIdsInConv.concat(listaIdsNotInConv)
        personaApiService.getPersonasByChatIds(listaIds) 
          .then(data => {
            console.log(data)
            if (data) {
              console.log(data)
              this.personas = data
              if (this.usuarios.length > 0) {
                this.usuarios.forEach(element => {
                  var persona = this.personas.filter(item => item.mspersonachatid == element.id)
                  if (persona.length>0) {
                    element.profile_image = 'data:image/png;base64, ' + persona[0].mspersonaimagen
                  }
                })
              }
            }
          })
          .catch(error =>{
            console.log(error)
          })
      }
    }
  })
</script>
<style scoped>

</style>