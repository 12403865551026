<template>
<v-app>
      <Menu />
      <header class="pl-16 v-app-bar">
        <div class="v-toolbar__content" style="height: 56px;">
          <v-row>
              <div elevation="0" class="pl-0 header-icon">
                <v-icon class="icon perfil"></v-icon>
              </div>
          </v-row>
        </div>
      </header>
    <v-main class="background profile">
       <v-card class="d-flex align-center" color="transparent" elevation="0">
        <v-form>
          <v-row class="align-end justify-center pb-5">
            <v-list-item-avatar  size="100">
              <v-img :src="foto"></v-img>
            </v-list-item-avatar>       
            <v-btn class="icon camara2"
              @click="$refs.fileInput.click()"
              fab
              color="primary"
              small
            ></v-btn>
            <input type="file" @change="onFileChange($event)" accept="image/*" class="input-file" ref='fileInput' style="display:none">
          </v-row>
             <v-text-field class="user"
              label="Nombre y apellido"
              color="accent"
              prepend-inner-icon=" "
              readonly
              v-model="nombreCompleto"
              ></v-text-field>
              <v-text-field class="dni"
              label="DNI"
              color="accent"
              v-model="dni"
              readonly
              prepend-inner-icon=" "
              ></v-text-field>
              <v-text-field 
                  class="telefono"
                  label="Teléfono"
                  color="accent"
                  v-model="telefono"
                  prepend-inner-icon=" "
              ></v-text-field>
              <v-text-field 
                  class="email"
                  label="Email"
                  color="accent"
                  v-model="email"
                  prepend-inner-icon=" "
              ></v-text-field>
              <!-- TODO: cambio contraseña -->
              <!-- <v-text-field 
                  class="password"
                  label="Contraseña antigua"
                  color="accent"
                  type="password"
                  v-model="passAnt"
                  prepend-inner-icon=" "
              ></v-text-field>
              <v-text-field 
                  class="password"
                  label="Nueva contraseña"
                  color="accent"
                  type="password"
                  v-model="pass"
                  prepend-inner-icon=" "
              ></v-text-field>
                 <v-text-field 
                  class="password"
                  label="Repetir contraseña"
                  color="accent"
                  type="password"
                  v-model="passRep"
                  prepend-inner-icon=" "
              ></v-text-field> -->
        </v-form></v-card>
        <v-btn class="icon guardar"
          @click="guardar"
          fab
          color="secondary"
          large
          elevation="0"
          bottom
          absolute
          right
        ></v-btn>
      <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
        :color="colorSnack"
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn
            fab
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>  
    </v-app>
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'
  import {PersonaApiService} from '../api/PersonaApiService'
  const personaApiService = new PersonaApiService()
  export default Vue.extend({
    name: 'Profile',
    data () {
      return {
        dni: '',
        nombreCompleto: '',
        telefono: '',
        email: '',
        foto: '',
        pass: '',
        passRep: '',
        passAnt: '',
        fotoFile: null,
        multiLine: true,
        snackbar: false,
        colorSnack: '',
        persona () {
          return {

          }
        }
      }
    },
    components: {
      Menu,
    },
    created() {
      this.persona = this.$store.state.userInfo.datos
      this.nombreCompleto = this.persona.mspersonanombre + ' ' + this.persona.mspersonaapellido1
      this.telefono = this.persona.mspersonatelmovil
      this.email = this.persona.mspersonaemail
      this.dni = this.persona.mspersonadni
      if (this.persona.mspersonaimagen !='' && this.persona.mspersonaimagen != null)  {
        this.foto = 'data:image/png;base64, ' + this.persona.mspersonaimagen
      } else {
        this.foto = require(`@/assets/images/no_photo.jpg`)
      }
    },
    methods: {
      guardarImagen () {
        let persona = {
          mspersonaid: this.$store.state.userInfo.id,
          imagen: this.fotoFile
        }
        personaApiService.updatePersonaFoto(persona, this.$store.state.userInfo.name)
          .then(data => {
            this.foto = 'data:image/png;base64, ' + data.data.mspersonaimagen 
            this.fotoFile = null
          })
          .catch(error => {
            console.log('updatePersonaFoto' + error)
            this.fotoFile = null
          })

      },
      guardar () {
        if (this.validateForm()) {
          let persona = { 
            mspersonausumodif: this.$store.state.userInfo.name,
            mspersonaid: this.$store.state.userInfo.id,
            mspersonanombre: this.nombreCompleto,
            mspersonaemail: this.email,
            mspersonatelmovil: this.telefono,
            mspersonadni: this.dni
          }
          personaApiService.updatePersona(persona)
            .then(data => {
              if (data.status === 200) {
                if (this.fotoFile !== null) {
                  this.guardarImagen()
                  this.snackbar = true;
                  this.colorSnack = 'green'
                  this.text = 'Actualizado correctamente'
                  // miramos si se quiere cambiar  la contraseña
                  if (this.pass !== '') {
                    this.cambiarPassword()
                  }
                }
              }
            })
            .catch(error => {
              this.snackbar = true;
              this.colorSnack = 'red lighten-1'
              this.text = 'Ha ocurrido un error'
              console.log('updatePersona ' + error)
            })
        } else {
          this.snackbar = true;
          this.colorSnack = 'red lighten-1'
          this.text = 'Error en el formulario'
        }
      },
      onFileChange (e) {
        const file = e.target.files[0]
        if (file) {
          this.foto = URL.createObjectURL(file)
          this.fotoFile = this.$refs.fileInput.files[0]
          // this.guardarImagen()
        }
      },
      validateForm() {
        if (this.isValidDni() && this.isValidEmail() && this.nombreCompleto !== '' && this.telefono !=='') {
          return true
        } else {
          return false
        }
      },
      isValidDni () {
        if (this.dni && this.dni !== '') {
          var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET-';
          var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
          var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
          var passRexp = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
          var str = this.dni.toString().toUpperCase();

          var isNIF = nifRexp.test(str);
          var isNIE = nieRexp.test(str);
          var isPass = passRexp.test(str);

          if (!isNIF && !isNIE && !isPass) {
            return false;
          }

          if (!isPass) {
            var nie = str
              .replace(/^[X]/, '0')
              .replace(/^[Y]/, '1')
              .replace(/^[Z]/, '2');
            var letter = str.substr(-1);
            var charIndex = parseInt(nie.substr(0, 8)) % 23;
            if (validChars.charAt(charIndex) === letter) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      },
      isValidEmail () {
        if (this.email && this.email !== '') {
          var emailRexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return emailRexp.test(this.email);
        } else {
          return false;
        }
      },
      cambiarPassword () {
        if (this.pass === this.passRep) {
          this.$store.dispatch('cambiarPass', { user: this.$store.state.userInfo.user, password: this.pass, passNueva: this.pass })
            .then(() => {
              // this.loading = false
              // this.cambioMensaje = true
              // setTimeout(() => {
              //   this.cambioMensaje = false
              // }, 5000)
              // this.mostrarCambio()
              this.snackbar = true;
              this.colorSnack = 'green'
              this.text = 'Contraseña actualizada correctamente'
            })
            .catch(error => {
              this.loading = false
              if (error.response.status === 403 || error.response.status === 401) {
              this.snackbar = true;
                this.colorSnack = 'red lighten-1'
                this.text = 'Contraseña incorrecta'
              } else {
                this.snackbar = true;
                this.colorSnack = 'red lighten-1'
                this.text = 'Ha ocurrido un error al cambiar la contraseña' 
              }
            })
        } else {
          this.snackbar = true;
          this.colorSnack = 'red lighten-1'
          this.text = 'Las contraseñas no coinciden'
        }
      }, 
    }
  })
</script>
