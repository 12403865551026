<template>
  <v-app class="workflow">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content transparent pb-3 mt-1" style="height: 56px;" background-color="transparent">
        <v-row class="d-flex align-center">
            <div elevation="0" class="pl-0 header-icon">
              <v-icon class="icon workflow"></v-icon>
            </div>
        </v-row>
      </div>
    </header>
        <Menu/>
     <v-main class="margin-top ">
            <v-list class="pb-0 border-list">
      <v-list-item-group
        
        color="primary"
      > 
    <v-list-item
        v-for="file in files"
        :key="file.title"
      >
        <v-list-item-content  @click="overlay = !overlay">
          <v-list-item-title class="primary--text text-subtitle-1 mb-0  text-uppercase" v-text="file.title"></v-list-item-title>
          <v-list-item-subtitle class="primary--accent" v-text="file.folder"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon text>
            <v-icon > 
              mdi-folder-move
              </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

        <v-overlay
           light
          class="theme--light main-overlay"
          :value="overlay"
        >
          <v-btn
            @click="overlay = false"
              right
              absolute
              fab
              top
              small
              color="accent"
              elevation="0"
          > <v-icon>mdi-close</v-icon>
          </v-btn>
           <v-card class="theme--light col-12 pt-10 pb-4 rounded-0" 
            v-for="file in files"
             :key="file.title">
            <v-row class="px-4">
              <v-list-item-title class="primary--text text-subtitle-1 mb-0  text-uppercase" v-text="file.title"></v-list-item-title>
              <v-list-item-subtitle class="grey--text" >Cambiar pedido de la carpeta {{ file.folder }}</v-list-item-subtitle>
            </v-row>
            <v-row class="px-4 flex-nowrap">
                <v-select
                  :items="items"
                  label="Seleccionar carpeta"
                  class="mr-4 "
                  color="accent"
                  v-model="motivo"
                ></v-select>
                <v-btn 
                  fab
                  color="secondary"
                  elevation="1"
                  large
                 @click="overlay = false"
                ><v-icon>mdi-folder-move</v-icon></v-btn>
              
            </v-row>
          </v-card>
        </v-overlay>
      </v-list-item-group>
    </v-list>


    </v-main>  
     
  </v-app>
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'

 

  export default Vue.extend({
    name: 'Notas',
    data () {
      return {
          absolute: true,
      overlay: false,
       items: ['Carpeta 1', 'Carpeta 2', 'Carpeta 3'],
     
      files: [
        { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
         { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
         { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
         { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
           { 
          title: 'Nombre del pedido',
          folder: 'Carpeta actual',

        },
        
        
      ],
      }
    },
    components: {
      Menu,
    },

  })
</script>
