<template>
  <v-app class="notes">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content transparent pb-3 mt-1" style="height: 56px;" background-color="transparent">
        <v-row class="d-flex align-center">
            <div elevation="0" class="pl-0 header-icon">
              <v-icon class="icon notas"></v-icon>
            </div>
        </v-row>
      </div>
    </header>
        <Menu/>
  <v-main class="margin-top">
    <v-card
        class="mx-4 rounded-0 mb-4"
       
        outlined
         v-for="file in files"
         :key="file.title"
      >
        <v-list-item 
          three-line
          >
          <v-list-item-content>
          <v-btn
            fab
            absolute
            top
            right
            color="primary"
            small
            class="elevation-0"
          >
          <v-icon >
            mdi-pencil
          </v-icon>
          </v-btn>
          <v-btn
          color="accent"
          fab
          absolute
          top
          right
          small
          class="elevation-0"
        >
          <v-icon >
            mdi-delete
          </v-icon>
      
        </v-btn>
            <v-card-title class="text-overline primary--text mb-0 pa-0">
              <v-icon class="primary--text mr-1" small> 
              mdi-pin
              </v-icon>
             {{ file.title }}
            </v-card-title>
            <v-card-subtitle class="pt-0 pl-0 accent--text">{{ file.date }}</v-card-subtitle>
            <v-card-text class="pl-0">{{ file.text }}</v-card-text>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-main>  
     
  </v-app>
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'

 

  export default Vue.extend({
    name: 'Notas',
    data () {
      return {
        datos: [],
        mostrar: false,
       files: [
        { 
          title: 'Helena Sanchez',
          date: '07/03/2021',
          text: 'Greyhound divisely hello coldly fonwderfully',

        },
           { 
          title: 'Helena Sanchez',
          date: 'C07/03/2021',
          text: 'Greyhound divisely hello coldly fonwderfully',

        },
           { 
          title: 'Helena Sanchez',
          date: '07/03/2021',
          text: 'Greyhound divisely hello coldly fonwderfully',
        },
           { 
          title: 'Helena Sanchez',
          date: '07/03/2021',
          text: 'Greyhound divisely hello coldly fonwderfully',
        },
         { 
          title: 'Helena Sanchez',
          date: '07/03/2021',
          text: 'Greyhound divisely hello coldly fonwderfully',
        },
        ]
      }
    },
    components: {
      Menu,
    },

  })
</script>
