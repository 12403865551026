<template>
  <v-app class="search">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content" style="height: 56px;">
        <Back />
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon search"></v-icon>
          </div>
        </v-row>
        <v-text-field class="buscar mr-3 d-flex" label="Localizador" append-outer-icon=" "></v-text-field>
      </div>
    </header>
    <Menu />
    <v-main>
      <v-card class="">
        <v-btn class="icon search" icon large v-bind="attrs" v-on="on"> </v-btn>
        <v-card-text class="d-flex px-0 pb-0 flex-column">
          <v-row class="col-12 pa-0 ma-0">
            <v-col class="d-flex py-0 align-center justify-space-between"> </v-col>
          </v-row>
          <v-row class="col-12 py-0 px-2 ma-0">
            <v-col class="col-12 ma-0 py-0"> </v-col>
          </v-row>
          <v-row class="col-12 pa-0 ma-0 scan">
            <v-col class="primary col-12 pa-0">
              <Scan />
            </v-col>
          </v-row>
          <v-list class="pa-0">
            <v-subheader class="text-uppercase">Historial de últimas busquedas</v-subheader>
            <v-list-item-group v-model="selectedItem" color="primary">
              <divider></divider>
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-img v-bind:src="item.src" class="white--text align-end" max-height="80" max-width="90" contain> </v-img>
                <v-list-item-content class="">
                  <v-list-item-title class="text-uppercase primary--text font-weight-medium" v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle class="text-uppercase accent--text" v-text="item.subtitle"></v-list-item-subtitle>
                  <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">Color</p>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
import Back from '../components/Shop/back.vue';
import Scan from '../components/Shop/scan.vue';

export default Vue.extend({
  name: 'Search',
  components: {
    Menu,
    Back,
    Scan,
  },
  data: () => ({
    items: [
      {
        src: require('@/assets/images/producto1.jpg'),
        title: 'zapatos summer',
        subtitle: `ref.000007 `,
      },
      {
        src: require('@/assets/images/producto1.jpg'),
        title: 'zapatos summer',
        subtitle: `ref.000007 `,
      },
      {
        src: require('@/assets/images/producto1.jpg'),
        title: 'zapatos summer',
        subtitle: 'ref.000007 ',
      },
    ],
  }),
});
</script>
