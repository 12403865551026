import axios from 'axios';
const URL_API_CALENLAB = process.env.VUE_APP_URL_API_CALENLAB;
const URL_API_DIATRABAJO = process.env.VUE_APP_URL_API_DIATRABAJO;

export class CalenlabApiService {
  /**
   * Devuelve todos los paises
   * @return Pais[]
   */
  getAllCalenlab() {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CALENLAB}getAllCalenlab`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Devuelve los calendarios dada una opuid
   * @return Calenlab[]
   */
  getAllCalenlabByOp(op) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CALENLAB}getAllCalenlabByOpuid`;
    return axios
      .post(url, op,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getDiatrabajoByAnhoAndOpuid(mscalenlabanho, msopcalenlabopuid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_DIATRABAJO + 'getDiatrabajoByAnhoAndOpuid/' + mscalenlabanho + '/' + msopcalenlabopuid;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  deleteDiatrabajo(diatrabajo) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_DIATRABAJO}deleteDiatrabajo`;
    return axios
      .post(url, diatrabajo, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  createDiatrabajo(diatrabajo) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_DIATRABAJO}createDiatrabajo`;
    return axios
      .post(url, diatrabajo, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
}
