<template>
  <v-btn class="back"
    icon
    large
  >
    <router-link to="/tienda"> 
      <v-icon class="icon atras" @click="reset()"></v-icon>
    </router-link>
   </v-btn>

  <!--
  
    <v-btn class="icon ticket">
    </v-btn>
</v-card>-->
</template>


<script lang="ts">
  import Vue from 'vue'

  export default Vue.extend({
    name: 'Back',
    methods: {
      reset () {
        this.$store.commit('setHasUserSearched', 0)
        this.$store.commit('setProductLocalizadorSearched', '')
        this.$store.commit('setProductEanSearched', '')
      }
    }
  })
</script>
