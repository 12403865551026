<template>
<v-app class="ticket">
       <header class="pl-16 v-app-bar"> 
        <div class="v-toolbar__content" style="height: 56px;">
          <Back/> 
          <v-row>
              <div elevation="0" class="pl-0 header-icon">
                <v-icon class="icon ticket"></v-icon>
              </div>  
          </v-row> 
              
        </div>
      </header>
        <Menu />
        <v-main>
           <v-card class="elevation-0">
            <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="icon ticket"
              icon
              large
              v-bind="attrs"
              v-on="on"
            >
            </v-btn>
          </template>
          <v-card-text class="pa-0">
              <v-row class="col-12 pa-0 ma-0">
                <v-col class="d-flex  align-center justify-space-between">
                  <span class="text-h6 d-flex align-center primary--text text-uppercase pa-0"><v-icon class="icon carro"></v-icon>Árticulos carrito</span>
                
              </v-col>
            </v-row>
                
          <v-tabs fixed-tabs centered color="secondary">
            <v-tab fixed-tabs>
              Carrito
            </v-tab>
            <v-tab fixed-tabs>
             Tickets
            </v-tab>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
               tab1
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat class="d-flex flex-column">
              
                 tab 2
                  <v-expansion-panels accordion class="elevation-0">
                    <v-expansion-panel class="transparent elevation-0 pa-0">
                      <v-expansion-panel-header class=" py-3 "><v-icon class="icon search"></v-icon></v-expansion-panel-header>
                      <v-expansion-panel-content class="px-0 pt-16">
                        <v-row class="col-12 pa-0 ma-0 scan">
                        <v-col class="primary col-12 pa-0">
                          <Scan/> 
                        </v-col>
                      </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels> 
              </v-card>
            </v-tab-item>
          </v-tabs>             







            
              
              
          
            <v-row class="col-12 py-0 px-2 ma-0">
              <v-col class="col-12 ma-0 py-0">
                
              </v-col>
            </v-row>
          
          </v-card-text>
          
        </v-card>
    </v-main>  
    </v-app>
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'
  import Back from '../components/Shop/back.vue'
  import Scan from '../components/Shop/scan.vue'

  export default Vue.extend({
    name: 'Search',
    components: {
      Menu,
      Back,
      Scan,
    },
        data: () => ({
      
     items: [
        {
          src: require("@/assets/images/producto1.jpg"),
          title: 'zapatos summer',
          subtitle: `ref.000007 `,
        },
        {
          src: require("@/assets/images/producto1.jpg"),
          title: 'zapatos summer',
          subtitle: `ref.000007 `,
        },
        {
          src: require("@/assets/images/producto1.jpg"),
          title: 'zapatos summer',
          subtitle: 'ref.000007 ',
        },
      ],
    }),
  })
</script>
