<template>
  <v-app>
    <v-app-bar elevation="0" class="pl-16" color="white">
      <Header />
    </v-app-bar>
    <Menu />
     
    <v-main class="order margin-top">
      <v-row class="col-12 pa-0 ma-0 scan">
        <v-col class="primary col-12 pa-0">
          <Scan />
        </v-col>
      </v-row>
      <v-list subheader two-line>
        <v-subheader class="text-uppercase">Confirmación nº11778 - Pedido nº663</v-subheader>

        <v-list-item v-for="folder in folders" :key="folder.title" to="/OrderDetail">
          <v-list-item-avatar>
            <v-icon class="accent" dark>
              mdi-archive
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="primary--text"><span v-text="folder.title"></span> <span v-text="folder.number"></span><span class="font-weight-black " v-text="folder.bold"></span></v-list-item-title>
            <v-list-item-subtitle class="accent--text" v-text="folder.subtitle"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon>
              <v-icon :color="folder.color" v-text="folder.icon"></v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader class="text-uppercase">Confirmación nº11778 - Pedido nº663</v-subheader>

        <v-list-item v-for="file in files" :key="file.title" to="/OrderDetail">
          <v-list-item-avatar class="accent">
            <v-icon dark> mdi-archive</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="primary--text"><span v-text="file.title"></span> <span v-text="file.number"></span><span class="font-weight-black" v-text="file.bold"></span></v-list-item-title>
            <v-list-item-subtitle class="accent--text" v-text="file.subtitle"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon>
              <v-icon :color="file.color" v-text="file.icon"></v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
import Header from '../components/Order/OHeader.vue';
import Scan from '../components/Shop/scan.vue';

export default Vue.extend({
  name: 'Profile',
  components: {
    Menu,
    Header,
    Scan
  },
  data: () => ({
    files: [
      { icon: 'mdi-information', color: 'grey lighten-1', subtitle: '3/03/2014', title: 'SSCC1', number: '0028434690000', bold: '255732' },
      { icon: 'mdi-close-circle', color: 'secondary', subtitle: '3/03/2014', title: 'SSCC2', number: '0028434690000', bold: '255732' },
    ],
    folders: [
      { icon: 'mdi-information', color: 'grey lighten-1', subtitle: '3/03/2014', title: 'SSCC3', number: '0028434690000', bold: '255732' },
      { icon: 'mdi-check-circle', color: 'success', subtitle: '3/03/2014', title: 'SSCC4', number: '0028434690000', bold: '255732' },
      { icon: 'mdi-check-circle', color: 'success', subtitle: '3/03/2014', title: 'SSCC5', number: '0028434690000', bold: '255732' },
    ],
  }),
});
</script>
