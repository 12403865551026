<template>
  <v-app class="chatDetail">
    <v-toolbar class="col-12 pa-0 ma-0 align-center elevation-0">
      <v-col class="d-flex py-0 align-center justify-space-between ">
        <v-row class="d-flex align-center">
          <v-btn class="back" icon large text color="primary">
            <router-link to="/Chat">
              <v-icon class="icon atras"></v-icon>
            </router-link>
          </v-btn>
          <v-avatar size="40px" elevation="2">
            <img alt="Avatar" :src="foto" />
            <div :class="[isOnline ? 'is-online' : 'is-offline']"></div>
          </v-avatar>
          <v-toolbar-title class="primary--text ml-3">
            {{ user.first_name }}
          </v-toolbar-title>
        </v-row>
      </v-col>
    </v-toolbar>
    <v-main class="background" id="conversacion">
      <v-list class="transparent">
        <v-list-item v-for="(mensaje, index) in mensajes" :key="index" two-line :class="[mensaje.user_id === userId ? 'entrant' : 'outgoing']" v-show="mensaje.message!=='inicio_conversacion'">
          <v-list-item-content :class="['rounded', mensaje.user_id !== userId ? 'float-right' : '']">
            <!-- <v-list-item-title class="primary--text font-weight-medium mb-2 d-none">Nombre de persona para grupos</v-list-item-title> -->
            <v-list-item-title>{{ mensaje.message }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{ mensaje.creation_time | chatDate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-main>
    <v-footer app class="grey grey lighten-3">
      <v-row class="py-5 px-2 d-flex align-center">
        <v-textarea label="Mensaje" auto-grow rows="1" solo elevation="0" row-height="15" v-model="mensaje"></v-textarea>
        <v-btn class="ml-2" fab dark elevation="0" color="primary" @click="sendMessage()">
          <v-icon dark>
            mdi-send
          </v-icon>
        </v-btn>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { ChatApiService } from '../api/ChatApiService';
import { PersonaApiService } from '../api/PersonaApiService'

const chatApiService = new ChatApiService()
const personaApiService = new PersonaApiService()

export default Vue.extend({
  name: 'ChatDetail',
  props: {
    conversationId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    agentId: {
      type: String,
      required: true,
    },
  },
  components: {},
  data: () => ({
    multiLine: true,
    snackbar: false,
    text: '',
    mensaje: '',
    mensajes: [],
    user: {},
    isOnline: false,
    foto: ''
  }),
  created() {
    this.interval2 = setInterval(() => this.isAgentOnline(), 1000);
    if (this.conversationId == -1) {
      chatApiService.newAgentsCovenrsation(this.userId, this.agentId)
        .then( data => {
          console.log('nueva conversacion')
          console.log(data)
          this.conversationId = data[1]
          this.init()
          this.interval = setInterval(() => this.getAgentConversation(), 1000)
        })
        .catch(error => {
          console.log(error)
          this.snackbar = true;
          this.text = 'Error al crear conversacion';
        })
    } else {
      this.init()
      this.interval = setInterval(() => this.getAgentConversation(), 1000)
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById("conversacion").scrollTop = document.getElementById("conversacion").scrollHeight
    })
  },
  destroyed() {
    clearInterval(this.interval)
    clearInterval(this.interval2)
  },
  methods: {
    init() {
      this.getPersonas()
      this.getAgentConversation()
    },
    getAgentConversation() {
      chatApiService
        .getAgentConversation(this.conversationId, this.agentId)
        .then((data) => {
          // console.log('conversacion');
          // console.log(data);
          if (data[1].messages.length > 0) {
            if (data[1].messages.length > this.mensajes.length) {
              this.mensajes = data[1].messages
              this.$nextTick(() => {
               document.getElementById("conversacion").scrollTop = document.getElementById("conversacion").scrollHeight
              })
            } else {
              this.mensajes = data[1].messages
            }      
            this.user = data[1].details
          } else {
            this.snackbar = true;
            this.text = 'No hay conversaciones';
          }
        })
        .catch((error) => {
          console.log(error);
          // this.snackbar = true;
          // this.text = 'Error get conversation';
        });
    },
    sendMessage() {
      if (this.mensaje !== '') {
        chatApiService
          .sendMessage(this.mensaje, this.conversationId, this.agentId)
          .then(() => {
            this.mensaje = ''
          })
          .catch((error) => {
            console.log(error)
            this.snackbar = true;
            this.text = 'Error al enviar el mensaje'
          });
      }
    },
    getNewMessages() {
      chatApiService
        .getNewMessages(this.conversationId, this.agentId)
        .then((data) => {
          console.log(1);
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
          // this.snackbar = true;
          // this.text = 'Error new messages';
        });
    },
    isAgentOnline(){
      chatApiService.isAgentOnline(this.userId)
        .then(data => {
          console.log(data)
          if (data[1]) {
            this.isOnline = true
          } else {
            this.isOnline = false
          }
        })
        .catch(error =>{
          console.log(error)
        })
    },
    getPersonas() {
        let listaIds = [this.userId]
        personaApiService.getPersonasByChatIds(listaIds) 
          .then(data => {
            if (data.length > 0) {
              this.foto = 'data:image/png;base64, ' + data[0].mspersonaimagen
            }
          })
          .catch(error =>{
            console.log(error)
          })
      }
  },
});
</script>
