<template>
  <v-list class="px-3 ma-0 pr-3 advange-list overflow-x-hidden">
    <h3 class="text-uppercase primary--text text-center py-3">Total: {{total}}</h3>
      <div class="ma-0 d-flex" v-for="(articulo, index) in articulos" :key="index"> 
        <v-list-item-avatar size="80" class="ma-0"  v-if="articulo.foto !== ''">
          <v-img :src="articulo.foto" class="pr-4 mr-2" max-height="80" max-width="90" contain> </v-img>
        </v-list-item-avatar> 
        <v-list-item>
          <v-list-item-content class="pb-0">
            <p class="text-subtitle-1 mb-0  primary--text pa-0 text-uppercase text-truncate text-overline">{{ articulo.ean }}</p>
            <v-list-item-title class="primary--text text-upper text-uppercase text-subtitle-1">{{ articulo.articulo.modelo}}</v-list-item-title>
            <v-list-item-subtitle class="accent--text pa-0 subtitle-1">{{ articulo.articulo.color }}</v-list-item-subtitle>
            <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate align-center"> 
              <span v-if="articulo.talla !== ''">Talla {{ articulo.talla }}</span>
              <!-- <span class="mx-2">Stock {{ articulo.stock }}</span> -->
              <span class="text-h6 ml-auto d-flex align-center px-3">
                <!-- TODO: sumar restar lineas -->
                <!-- <v-btn color="secondary" icon outlined @click="(articulo.unidades > 0) ? restar(articulo.ean) : ''"><v-icon>mdi-minus</v-icon></v-btn> -->
                 <v-text-field disabled class="px-3 py-0" :value="articulo.unidades "> </v-text-field>
                <!-- <v-btn icon color="secondary" outlined @click="sumar(articulo.ean)"><v-icon>mdi-plus</v-icon></v-btn>  -->
              </span>
            </p>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-btn text absolute right top icon primary class="pa-0">
          <v-icon @click="eliminar(index)">mdi-delete</v-icon>
        </v-btn> -->
      </div>
 </v-list>
</template>

<script lang="ts">
import Vue from 'vue';
// import { TiendaApiService } from '../api/TiendaApiService';
// const tiendaApiService = new TiendaApiService();

export default Vue.extend({
  name: 'AdvangeList',
  components: {

  },
  props: {
    total: {
      type: Number,
      required: false,
    },
  },
  data() {
   return {
      e6: 0,
      snackbar: false,
      multiLine: true,
      snackColor: '',
      snackText: '',
      opId: '',
      opUid: '',
      ean: '',
      foto: '',
      tallas: [],
      dialog: false,
      tipo: 'completo',
      sheet: true,
    };
  },
  computed: {
    cambiotienda () {
      return this.$store.getters.getIdTienda;
    },
    cambiotiendaUid () {
      return this.$store.getters.getOpuid;
    },
    articulos () {
      return this.$store.state.inventarioArticulosAvanzado
    }
  },
  watch: {
    cambiotienda (value) {
      this.opId = value
      this.init()
    },
    cambiotiendaUid (value) {
      this.opUid = value
      this.init()
    }
  },
  mounted() {
    this.opId = this.$store.getters.getIdTienda
    this.opUid = this.$store.getters.getOpuid
    this.init()
    // this.searchByEanCode('3700000500308')
  },
  methods: {
    init() {
      this.ean = '' 
    },
    sumar(ean) {
      this.$store.commit('addUdInventarioArticulosAvanzado', ean)
    },
    restar(ean) {
      this.$store.commit('removeUdInventarioArticulosAvanzado', ean)
    },
    // searchByEanCode() {
    //   // alert(ean)
    //   if (this.ean.length > 0) {
    //     let encontrado
    //     for (var x=0; x <  this.articulos.length; x++) {
    //       if (this.articulos[x].ean === this.ean) {
    //         this.$store.commit('addUdInventarioArticulosAvanzado', this.ean)
    //         encontrado = true
    //         this.init()
    //       }
    //     }
    //     if (!encontrado) {
    //       this.getProductBy(this.ean)
    //     }
    //   } else {
    //     this.snackbar = true
    //     this.snackColor = 'red darken-2'
    //     this.snackText = 'No se ha podido escanear el código'
    //   }
    // },
    // getProductBy(ean) {
    //   // var filtro = {}
    //   // Object.assign(filtro, { ean: ean })
    //   tiendaApiService
    //     .getArticuloByCod(ean)
    //     .then((data) => {
    //       let foto, stock = 0, unidades = 1, talla, stockObj
    //       if (data.articulo !== null) {
    //         if (data.articulo.fotos.length > 0) {
    //           foto = data.articulo.fotos[0].url;
    //         } else {
    //           foto= ''
    //         }
    //         outer_loop:
    //         for (var x=0; x < data.articulo.tallas.length; x++) {
    //           for (var y=0; y < data.articulo.tallas[x].eans.length; y++) {
    //             if (data.articulo.tallas[x].eans[y].ean === ean) {
    //               talla = data.articulo.tallas[x].talla
    //               if (data.articulo.tallas[x].stocks.length > 0) {
    //                 stockObj = data.articulo.tallas[x].stocks.find(element => element.id.id_op === this.opId)
    //                 if (stockObj) {
    //                   stock = stockObj.unidades
    //                 }
    //               }
    //               break outer_loop
    //             }
    //           } 
    //         }
    //         this.$store.commit('addToInventarioArticulosAvanzado',{
    //                             'articulo': data.articulo,
    //                             'talla': talla,
    //                             'stock': stock,
    //                             'unidades': unidades,
    //                             'foto': foto,
    //                             'ean': ean,
    //                             })
    //         this.$nextTick(() => {
    //            window.scrollTo(0,document.body.scrollHeight);
    //         })   
    //         this.init()
    //       } else {
    //         this.snackbar = true
    //         this.snackColor = 'red darken-2'
    //         this.snackText = 'No se ha encontrado el articulo'
    //         this.init()
    //       }
    //     })
    //     .catch((error) => {
    //       this.init()
    //       this.snackbar = true
    //       this.snackColor = 'red darken-2'
    //       this.snackText = 'Ha ocurrido un error'
    //       console.log('tiendaApiService - findArticulos ' + error)
    //     });
    // },
    // guardarInventario() {           
    //   this.$root.$confirm.open('Enviar', '¿Deseas enviar el inventario?', { color: 'primary' }).then((confirm) => {
    //     if (confirm) {
    //       let inventario = []
    //       inventario.push(this.tipo)
    //       inventario.push(this.$store.state.inventarioArticulosAvanzado.map((element) => {
    //       return element.ean
    //       }) )
    //       console.log(inventario)
    //       tiendaApiService.enviarInventario(inventario)
    //       .then(() => {
    //       this.snackbar = true
    //       this.snackColor = 'green'
    //       this.snackText = 'Inventario registrado con éxito'
    //       })
    //       .catch(error => {
    //       this.snackbar = true
    //       this.snackColor = 'red darken-2'
    //       this.snackText = 'Error al enviar el inventarío'
    //       console.log('tiendaApiService - enviarInventario ' + error)
    //       })
    //       this.$store.state.inventarioArticulosAvanzado = []
    //     }
    //   })
    // },
    eliminar(index) {
      this.$root.$confirm.open('Borrar', '¿Deseas borrar el articulo?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          this.$store.commit('removeFromInventarioArticulosAvanzado',index)
        }
        this.$emit('iniciar')
      })
    }
  },
});
</script>
