<template>
  <v-app class="order">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content transparent" style="height: 56px;">
        <v-btn class="back white" icon large>
          <router-link to="/Order">
            <v-icon class="icon atras"></v-icon>
          </router-link>
        </v-btn>
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon caja"></v-icon>
          </div>
        </v-row>
      </div>
    </header>
    <Menu />
    <v-main>
      <v-row class="col-12 pa-0 ma-0 scan">
        <v-col class="primary col-12 pa-0">
          <Scan />
        </v-col>
      </v-row>
      <v-card class="scroll-order">
        <v-card-title class="d-flex d-flex align-center py-3 text-h6 font-weight-light primary--text">
          <v-list-item-avatar class="accent my-0 ">
            <v-icon dark> mdi-archive</v-icon>
          </v-list-item-avatar>
          SSCC<span class=" font-weight-regular pl-1">0028434690000<span class="font-weight-black">255732</span></span>
        </v-card-title>
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(item, i) in 5" :key="i">
            <v-expansion-panel-header class="px-2">
              <v-img :src="require('../assets/images/producto1.jpg')" class="pr-4" max-height="80" max-width="90" contain> </v-img>
              <v-card-title class="product d-flex pa-0 flex-column justify-start align-start">
                <p class="text-subtitle-1 mb-0 font-weight-bold primary--text pa-0 text-uppercase">zapatos summersummersummer summer</p>
                <p class=" mb-0 accent--text pa-0 subtitle-1 d-flex">ref.000007</p>
                <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">Brandy</p>
              </v-card-title>

              <v-icon color="grey lighten-1">mdi-information</v-icon>
              <!-- Este cuando todos están escaneados
           <v-icon color="success">mdi-check-circle</v-icon>
          -->
              <!-- Este cuando todos están escaneados
           <v-icon color="secondary">mdi-close-circle</v-icon>
          -->
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="d-flex justify-start ma-0 stock-table">
                <v-col class="d-flex pa-0 flex-column align-center jusitify-start flex-grow-0" v-for="card in cards" :key="card">
                  <p class="px-4 py-1 text-body-1 font-weight-medium primary--text mb-0 text-center" v-text="card.size"></p>
                  <p class="pa-1 mb-0 text-center grey--text text--darken-2" v-text="card.stock"></p>
                  <v-text-field class="pt-0 text-h6 text-center" label="Stock"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-main>
    <v-btn class="secondary elevation-1" icon x-large absolute bottom right>
      <v-icon color="white">mdi-check</v-icon>
    </v-btn>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
import Scan from '../components/Shop/scan.vue';

export default Vue.extend({
  name: 'Profile',
  components: {
    Menu,

    Scan,
  },
  data: () => ({
    cards: [
      { size: '37', stock: '4', scan: '2' },
      { size: '38', stock: '4', scan: '2' },
      { size: '39', stock: '4', scan: '2' },
      { size: '40', stock: '4', scan: '2' },
      { size: '41', stock: '4', scan: '2' },
      { size: '42', stock: '4', scan: '2' },
      { size: '37', stock: '4', scan: '2' },
      { size: '38', stock: '4', scan: '2' },
      { size: '39', stock: '4', scan: '2' },
      { size: '40', stock: '4', scan: '2' },
      { size: '41', stock: '4', scan: '2' },
      { size: '42', stock: '4', scan: '2' },
    ],
  }),
});
</script>
