<template>
  <v-app class="product-page">
    <header class="pl-13 v-app-bar">
      <div class="v-toolbar__content" style="height: 56px;">
        <div elevation="0" class="pl-0 header-icon">
          <v-icon class="icon carro"></v-icon>
        </div>
        <Back /> <Header />
      </div>
    </header>
    <Menu />
    <v-main class=" col-12">
      <v-card class="elevation-0 fixed margin-top col-12 pa-0">
        <v-carousel show-arrows-on-hover height="330">
          <!--cycle-->
          <v-carousel-item v-for="(item, i) in fotos" :key="i">
            <v-row class="fill-height" align="center" justify="center">
              <v-img v-bind:src="item" class="white--text align-end pb-2" gradient="to bottom, rgba(0,0,0,.0), rgba(0, 0, 0, 0.2)" contain height="350"> </v-img>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-card>
      <v-card class="product-info">
        <v-card-title class="body-2 text-uppercase primary--text pb-0">
          <span class="text-h5 col-12 font-weight-bold pa-0">{{ productAux.modelo }} || {{ productAux.color }}</span>
          <span class="font-weight-light col-12 pa-0 d-flex"
             v-if="productAux.ecomercio != null">Cod e-com. {{ productAux.ecomercio }}</span>
          <span class="font-weight-light col-12 pa-0 title d-flex">
            loc.{{ productAux.localizador }}
            <v-btn class="ml-auto" icon @click="show = !show">
              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </span>
        </v-card-title>
        <v-expand-transition>
          <div v-show="show">
            <v-card-text class="pt-0">
              {{ productAux.descripcion }}
            </v-card-text>
            <v-divider></v-divider>
          </div>
        </v-expand-transition>
        <v-card-actions>
          <v-card class="elevation-0 flex-grow-1" tile>
            <v-list flat class="color pb-4">
              <v-list-item-group class="d-flex flex-wrap" color="primary">
                <v-list-item v-for="color in colores" :key="color.id" v-show="color.color !== productAux.color">
                  <v-img v-bind:src="getFotoColores(color)" @click="cambiarArticulo(color)" class="white--text align-end pb-2" height="65px" width="65px" contain> </v-img>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-list flat class="size pt-4">
              <v-list-item-group class="d-flex flex-wrap" v-model="tallaInterna" color="primary" @change="getPrecCodStock">
                <v-list-item v-for="(item, i) in productAux.tallas" :key="i" v-show="hayStock(item.tallaInterna)" :value="item.tallaInterna">
                  <v-list-item-title class="text-h5 text-center" v-text="item.talla"></v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-row class="d-flex ma-0 py-3 px-4 justify-end">
              <p class="text-h3 mb-0 py-2 primary--text mr-6 font-weight-bold">{{ precio }}€</p>
              <v-btn v-if="precio !== '' && codigoEanActual !== '' && talla !== ''" class="icon addticket" fab color="secondary" elevation="1" bottom right large @click="addToCart()"> </v-btn>
            </v-row>
          </v-card>
        </v-card-actions>
      </v-card>

      <!-- <v-card class="elevation-0 d-flex table">
      <template class="">
        <v-data-table
          dense
          fixed-header
          disable-sort
          :headers="tablesize"
          :items="store"
          class="elevation-1"
          mobile-breakpoint="0"
          disable-pagination
          hide-default-footer
        >
        </v-data-table>
      </template>
    </v-card>-->
      <div class="elevation-0 table stock-detalle">
        <v-tabs
          v-if="colores.length > 0"
          :v-model="1"
          fixed-tabs
          background-color="#1f384c"
          color="#FFFFFF"
        >
          <v-tab
            key="1"
            @click="stockPorColor = false"
          >
            Tallas
          </v-tab>
          <v-tab
            key="2"
            @click="stockPorColor = true"
          >
            Colores
          </v-tab>
        </v-tabs>
        <v-data-table 
          v-if="!stockPorColor"
          :headers="headers" 
          :items="stock2" 
          disable-pagination hide-default-footer mobile-breakpoint="0" disable-sort>
          <template v-slot:body="{ items, headers }">
            <tbody>
              <tr v-for="(item, idx) in items" :key="idx">
                <td v-for="(header, key) in headers" :key="key">
                  <!-- TODO: movimiento de stock intertiendas de momento no lo mostramos-->
                  <!-- <v-edit-dialog v-if="item.store !== 'Total' && item[header.value] != 0 && item[header.value] != '' && permitido('MOBILESTORE.PHONESERVICES.TIENDA.MOVSTOCK')" ref="dialogMovStock" :return-value.sync="item[header.value]" @save="moverStock(header,item[header.value])" @cancel="cancel" @close="close" cancel-text="Cerrar" save-text="Guardar" large>
                    <div @click="selecStock(item,item[header.value])">{{ item[header.value] }}</div>
                    <template v-slot:input>
                      <p class="subtitle-2 mb-0 py-2 px-2 text-uppercase white--text "><v-icon class="icon caja"></v-icon> Control de stock &#8594; talla {{ header.value }}</p>
                      <v-col>
                        <v-row class="pt-2 px-4">
                          <v-col class="d-flex py-0" cols="5">
                            <v-select v-model="store1" :readonly="store1RO" item-value="msopuid" :items="tiendas" item-text="msopclicode" label="De tienda"></v-select>
                          </v-col>
                          <v-col class="d-flex pt-3 px-0" cols="2">
                            <v-icon class="icon atras"></v-icon>
                          </v-col>
                          <v-col v-model="e2" class="d-flex py-0" cols="5">
                            <v-select v-model="store2" :readonly="store2RO" item-value="msopuid" :items="tiendas" item-text="msopclicode" label="A tienda"></v-select>
                          </v-col>
                        </v-row>
                        <div class="d-flex align-center">
                          <v-btn color="secondary" icon outlined
                            ><v-icon @click="unidadesMov < item[header.value] ? unidadesMov++ : ''">
                              mdi-plus
                            </v-icon>
                          </v-btn>
                          <v-text-field class="text-h5 pb-0 px-4" v-model="unidadesMov" label="Unidades"></v-text-field>
                          <v-btn color="secondary" icon outlined>
                            <v-icon @click="unidadesMov > 1  ? unidadesMov-- : ''">
                              mdi-minus
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </template>
                  </v-edit-dialog> -->
                  <!-- <div v-else>{{ item[header.value] }}</div> -->
                  <div>{{ item[header.value] }}</div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-data-table 
          v-else
          :headers="headersColor" 
          :items="stockColor" 
          disable-pagination hide-default-footer mobile-breakpoint="0" disable-sort>
          <template v-slot:body="{ items, headers }">
            <tbody>
              <tr v-for="(item, idx) in items" :key="idx">
                <td v-for="(header, key) in headers" :key="key">
                  <div>{{ item[header.value] }}</div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}

          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" icon fab text @click="snack = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
import Header from '../components/Shop/SHeader.vue';
import Back from '../components/Shop/back.vue';
import { TiendaApiService } from '../api/TiendaApiService';
import { TicketApiService } from '../api/TicketApiService';
import { Util } from '../api/Util';

const tiendaApiService = new TiendaApiService();
const ticketApiService = new TicketApiService();
const util = new Util();
export default Vue.extend({
  name: 'Shop',
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  components: {
    Menu,
    Header,
    Back,
  },
  data() {
    return {
      e2: '',
      tallaInterna: '',
      talla: '',
      cantidad: 1,
      fotoDefecto: '',
      fotoSecundaria: '',
      precio: '',
      precios: [],
      codigoEanActual: '',
      codigosEan: [],
      medidas: [],
      medida: '',
      opId: '',
      opUid: '',
      cliCode: '',
      unidadesDisp: 0,
      stockReservado: [],
      fotos: [],
      tallas: [],
      total: [],
      ops: [],
      colores: [],
      stockPorColor: false,
      relacionados: [],
      error: false,
      productAux: {},
      tiendas: [],
      options: {
        rewind: true,
        arrows: true,
        pagination: true,
        autoplay: true,
      },
      likeList: [],
      isInLikeList: false,
      mostrarLikeList: false,
      mostrarBotones: false,
      show: false,
      dialog: false,
      dialogDelete: false,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      snack: false,
      snackColor: '',
      snackText: '',
      store1: '', //tienda a la que pides stock
      store2: '', //Tu tienda
      store1RO: true,
      store2RO: false,
      headers: [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'store',
          class: 'primary white--text text-subtitle-1',
        },
      ],
      headersColor: [
         {
          text: '',
          align: 'center',
          sortable: false,
          value: 'store',
          class: 'primary white--text text-subtitle-1',
        },
      ],
      unidadesMov: 0,
      stock2: [],
      stockColor: []
    };
  },
  created() {
    if (this.cliente !== null) {
      // this.addToHistorial()
    }
    this.getArticulo()
    this.tiendas = this.$store.state.userInfo.iniOps
    this.store1 = this.tiendas[0].msopuid
    this.store2 = this.tiendas[0].msopuid
    this.opId = this.$store.getters.getIdTienda
    this.opUid = this.$store.getters.getOpuid 
    // this.init()
  },
  computed: {
    cliente() {
      return this.$store.getters.getCliente
    },
    cambiotienda () {
      return this.$store.getters.getIdTienda;
    },
    cambiotiendaUid () {
      return this.$store.getters.getOpuid;
    },
  },
  watch: {
    cliente(value) {
      if (value !== null) {
        this.mostrarLikeList = true
      }
    },
    cambiotienda (value) {
      this.opId = value
    },
    cambiotiendaUid (value) {
      this.opUid = value
      this.init()
    }
  },
  methods: {
    init() {
      this.fotos = []
      this.tallaInterna = ''
      this.getColores()
      // this.getLikeList()
      this.getStockReservadoByEansOps()
      this.getFotos()
      // this.getArticulosRelacionados()
    },
    getArticulo() {
      tiendaApiService
        .getArticuloById(this.productId)
        .then((data) => {
          this.productAux = data
          this.init()
        })
        .catch((error) => {
          this.cargando = false
          // alert('Ha ocurrido un error')
          this.productos = []
          console.log('tiendaApiService - getArticuloById ' + error)
        })
    },
    getArticulosRelacionados() {
      tiendaApiService
        .getArticulosRelacionados(this.productAux.id)
        .then((data) => {
          // console.log('tiendaApiService - getArticulosRelacionados', data.length, 'OK')
          // console.log(data)
          this.relacionados = data
        })
        .catch((error) => {
          console.log('tiendaApiService - getArticulosRelacionados ' + error)
        })
    },
    getPrecioRelacionado(producto) {
      let precioAux = ''
      let pass = false
      for (var i = 0; i < producto.tallas.length; i++) {
        if (producto.tallas[i].stocks.length > 0) {
          let stockOp = producto.tallas[i].stocks.find((stock) => stock.id.id_op === this.opId);
          if (stockOp) {
            if (stockOp.precio) {
              precioAux = stockOp.precio.precio;
              pass = true;
            }
          } else {
            for (var x = 0; x < producto.tallas[i].stocks.length; x++) {
              if (producto.tallas[i].stocks[x].precio) {
                precioAux = producto.tallas[i].stocks[x].precio.precio;
              }
            }
          }
        }
        if (pass) {
          break;
        }
      }
      return util.formatMoney(precioAux);
    },
    getPrecios() {
      tiendaApiService
        .getPreciosByIdArtandIdOp(this.productAux.id, this.opId)
        .then((data) => {
          // console.log('tiendaApiService - getPreciosByIdArtandIdOp', data.length, 'OK')
          //console.log(data)
          this.precios = data;
          this.getPrecCodStock();
        })
        .catch((error) => {
          console.log('tiendaApiService - getPreciosByIdArtandIdOp ' + error);
        });
    },
    getPrecCodStock() {
      if (this.tieneArtbox()) {
        this.crearMedida();
      }
      for (var i = 0; i < this.productAux.tallas.length; i++) {
        if (this.productAux.tallas[i].tallaInterna === this.tallaInterna) {
          this.talla = this.productAux.tallas[i].talla;
          if (this.productAux.tallas[i].stocks.length > 0) {
            // let stockOp
            // for (var y = 0; y < this.productAux.tallas[i].stocks.length; y++) {
            //   if (this.productAux.tallas[i].stocks[y].unidades > 0) {
            //     stockOp = this.productAux.tallas[i].stocks[y]
            //     break
            //   }
            // }
            // *** SOLO si queremos que haya stock en la tienda seleccionada ****///
            let stockOp = this.productAux.tallas[i].stocks.find((stock) => stock.id.id_op === this.opId);
            if (stockOp) {
              if (stockOp.unidades > 0) {
                this.unidadesDisp = stockOp.unidades;
              } else {
                this.unidades = 0;
              }
              if (stockOp.precio) {
                this.precio = stockOp.precio.precio;
              } else {
                this.precio = '';
              }
            } else {
              this.unidadesDisp = 0;
              this.precio = '';
              let precioAux = this.precios.find((precio) => precio.id.talla_interna == this.productAux.tallas[i].tallaInterna);
              if (precioAux !== undefined) {
                this.precio = precioAux.precio;
              }
            }
          } else {
            this.unidadesDisp = 0;
            this.precio = '';
          }
          //sacamos el codigo
          this.codigoEanActual = this.getCodigoEan(this.productAux.tallas[i]);
          this.getStockColores();
        }
      }
    },
    getCodigoEan(talla) {
      let codAux = '';
      if (talla.eans.length > 0) {
        for (var x = 0; x < talla.eans.length; x++) {
          if (!isNaN(talla.eans[x].ean) && talla.eans[x].ean.toString().length === 13) {
            codAux = talla.eans[x].ean;
          }
        }
      } else {
        //No hay generamos el ean13
        codAux = util.generarEan13(talla.tallaInterna, talla.talla, this.productAux.localizador);
      }
      return codAux;
    },
    hayStock(tallaInterna) {
      let medidaAux = this.medidas.find((medida) => medida.tallaInterna === tallaInterna);
      // miramos primero si esa talla esta bloqueada
      if (!this.tieneArtbox()) {
        //por configuracion si no tienen artbox entonces no tenemos en cuenta y mostramos siempre
        medidaAux = {};
        medidaAux.bloqueado = 'N';
      }
      if (medidaAux) {
        if (medidaAux.bloqueado === 'N') {
          for (var i = 0; i < this.productAux.tallas.length; i++) {
            if (this.productAux.tallas[i].tallaInterna === tallaInterna) {
              if (this.productAux.tallas[i].stocks.length > 0) {
                for (var y = 0; y < this.productAux.tallas[i].stocks.length; y++) {
                  if (this.productAux.tallas[i].stocks[y].unidades > 0) {
                    return true;
                  }
                }
                // *** SOLO si queremos que haya stock en la tienda seleccionada ****///
                //  let stockOp = this.productAux.tallas[i].stocks.find( stock => stock.id.id_op === this.opId );
                //  if (stockOp) {
                //    if (stockOp.unidades > 0) {
                //      return true
                //    } else {
                //      return false
                //    }
                //  } else{
                //    return false
                //  }
              } else {
                return false;
              }
            }
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    /** Función que crea la tabla de stock**/
    getStock() {
      let pass;
      this.stock = [[]];
      this.stock2 = [];
      this.tallas = [];
      this.headers = [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'store',
          class: 'primary white--text text-subtitle-1',
        },
      ];
      this.total = [];
      this.ops = [];
      let unidadesReserva = 0;
      let reservaAux;
      let bloqueado;
      this.cliCode = '';
      for (var i = 0; i < this.productAux.tallas.length; i++) {
        let medidaAux = this.medidas.find((medida) => medida.tallaInterna === this.productAux.tallas[i].tallaInterna);
        // miramos primero si esa talla esta bloqueada
        if (!this.tieneArtbox()) {
          //por configuracion si no tienen artbox entonces no tenemos en cuenta y mostramos siempre
          medidaAux = {};
          medidaAux.bloqueado = 'N';
        }
        if (medidaAux) {
          bloqueado = medidaAux.bloqueado;
        } else {
          bloqueado = 'S';
        }
        this.tallas.push({ talla: this.productAux.tallas[i].talla, bloqueado: bloqueado });
        this.headers.push({ text: this.productAux.tallas[i].talla, value: this.productAux.tallas[i].talla, class: 'primary white--text text-subtitle-1' });
        this.total.push({ talla: this.productAux.tallas[i].talla, cantidad: 0, bloqueado: bloqueado });
        //stock.push(this.productAux.tallas[i].talla)
        this.stock[i] = new Array(this.ops.length);
        for (var y = 0; y < this.productAux.tallas[i].stocks.length; y++) {
          pass = false;
          for (var z = 0; z < this.ops.length; z++) {
            if (this.productAux.tallas[i].stocks[y].op !== null) {
              if (this.ops[z] === this.productAux.tallas[i].stocks[y].op.msopclicode) {
                if (this.productAux.tallas[i].stocks[y].unidades > 0) {
                  if (!isNaN(this.stock[i][z])) {
                    this.stock[i][z] = this.stock[i][z] + this.productAux.tallas[i].stocks[y].unidades;
                    this.total[i].cantidad += this.productAux.tallas[i].stocks[y].unidades;
                  } else {
                    this.stock[i][z] = this.productAux.tallas[i].stocks[y].unidades;
                    this.total[i].cantidad += this.productAux.tallas[i].stocks[y].unidades;
                  }
                  pass = true;
                }
              }
            }
          }
          if (!pass) {
            // ahora buscamos si hay stock reservado en la tienda
            if (this.stockReservado.length > 0) {
              reservaAux = this.stockReservado.find((reserva) => reserva.opId === this.productAux.tallas[i].stocks[y].id.id_op && reserva.talla_interna === this.productAux.tallas[i].tallaInterna);
              if (reservaAux) {
                unidadesReserva = reservaAux.unidades;
                console.log(unidadesReserva);
              } else {
                unidadesReserva = 0;
              }
            }
            // miramos si la tienda esta en las tiendas del tpv.ini
            let tiendaAux = this.tiendas.find((tienda) => tienda.msopid === this.productAux.tallas[i].stocks[y].id.id_op);
            if (tiendaAux) {
              if (this.productAux.tallas[i].stocks[y].unidades > 0) {
                //si la primera talla tiene stock y precio ok la seleccionamos
                if (this.tallaInterna === '') {
                  this.tallaInterna = this.productAux.tallas[i].tallaInterna;
                  if (this.productAux.tallas[i].stocks[y].precio) {
                    this.precio = this.productAux.tallas[i].stocks[y].precio.precio;
                  }
                }
               
                if (tiendaAux.msopid === this.opId || tiendaAux.msopid === process.env.VUE_APP_OPIDALMACEN) {
                  this.ops.unshift(this.productAux.tallas[i].stocks[y].op.msopclicode)
                  for (let index = 0; index < this.stock.length; index++) {
                    if (index===i) {
                      this.stock[i].unshift(this.productAux.tallas[i].stocks[y].unidades - unidadesReserva)
                    } else {
                      this.stock[index].unshift('')
                    }       
                  }
                }  else {
                  this.ops.push(this.productAux.tallas[i].stocks[y].op.msopclicode);
                  this.stock[i].push(this.productAux.tallas[i].stocks[y].unidades - unidadesReserva);
                }
                this.total[i].cantidad += this.productAux.tallas[i].stocks[y].unidades - unidadesReserva;
              }
            }
          }
        }
      }
      /* ahora formamos la tabla de stock */
      let lineaStock = new Object();
      for (var x = 0; x < this.ops.length; x++) {
        lineaStock = {
          id: x + 1,
          store: this.ops[x],
        };
        var tallaLit;
        for (y = 0; y < this.tallas.length; y++) {
          tallaLit = this.tallas[y].talla;
          if (this.stock[y][x] === undefined) {
            lineaStock[tallaLit] = '';
          } else {
            lineaStock[tallaLit] = this.stock[y][x];
          }
        }
        // if (this.ops[x] === this.cliCode) {
        //   this.stock2.unshift(lineaStock);
        // } else {
          this.stock2.push(lineaStock);
        // }
         
      }
      if (this.total.length > 0) {
        let lineaStockTotal = new Object();
        lineaStockTotal = {
          id: x + 1,
          store: 'Total',
        };
        for (y = 0; y < this.tallas.length; y++) {
          tallaLit = this.tallas[y].talla;
          lineaStockTotal[tallaLit] = this.total[y].cantidad;
        }
        this.stock2.push(lineaStockTotal);
      }
      this.getPrecios();
    },
    getStockColores() {
      if (this.colores.length > 0 && this.tallaInterna !== '') {
        this.stockColoresAux = [[]]
        this.stockColor = []
        this.headersColor = [
          {
            text: '',
            align: 'center',
            sortable: false,
            value: 'store',
            class: 'primary white--text text-subtitle-1',
          },
        ];
        this.totalColores =[]
        for (var x = 0; x < this.colores.length; x++) {
          this.headersColor.push({ text: this.colores[x].color, value: this.colores[x].color, class: 'primary white--text text-subtitle-1' });
          this.stockColoresAux[x] = new Array(this.ops.length)
          this.totalColores[x] = 0
          for (var i = 0; i < this.colores[x].tallas.length; i++) {
            if (this.colores[x].tallas[i].tallaInterna === this.tallaInterna) {
              for (var y = 0; y < this.colores[x].tallas[i].stocks.length; y++) {
                if (this.colores[x].tallas[i].stocks[y].op !== null) {
                  const indexOpAux = this.ops.findIndex(element => element == this.colores[x].tallas[i].stocks[y].op.msopclicode)
                  if (indexOpAux !== -1) {
                    if (this.colores[x].tallas[i].stocks[y].unidades > 0) {
                      this.stockColoresAux[x][indexOpAux] = this.colores[x].tallas[i].stocks[y].unidades
                      this.totalColores[x] += this.colores[x].tallas[i].stocks[y].unidades
                    }
                  } else {
                    //no esta la tienda en las ops anteriores de stock, la añadimos
                    if (this.colores[x].tallas[i].stocks[y].unidades > 0) {
                      this.ops.push(this.colores[x].tallas[i].stocks[y].op.msopclicode);
                      this.stockColoresAux[x].push(this.colores[x].tallas[i].stocks[y].unidades);
                      this.totalColores[x] += this.colores[x].tallas[i].stocks[y].unidades
                    }
                  }
                }
              }
              break
            }
          }
        }
         /* ahora formamos la tabla de stock */
        let lineaStock = new Object();
        for (var p = 0; p < this.ops.length; p++) {
          lineaStock = {
            id: p + 1,
            store: this.ops[p],
          };
          var colorLit;
          for (y = 0; y < this.colores.length; y++) {
            colorLit = this.colores[y].color;
            if (this.stockColoresAux[y][p] === undefined) {
              lineaStock[colorLit] = '';
            } else {
              lineaStock[colorLit] = this.stockColoresAux[y][p];
            }
          }
          if (this.ops[p] === this.cliCode) {
            this.stockColor.unshift(lineaStock);
          } else {
            this.stockColor.push(lineaStock);
          }
          
        }
        if (this.totalColores.length > 0) {
          let lineaStockTotal = new Object();
          lineaStockTotal = {
            id: p + 1,
            store: 'Total',
          };
          for (y = 0; y < this.colores.length; y++) {
            colorLit = this.colores[y].color;
            lineaStockTotal[colorLit] = this.totalColores[y];
          }
          this.stockColor.push(lineaStockTotal);
        }
      }
    },
    getStockReservadoByEansOps() {
      //sacamos los codigos ean para coger el stock reservado
      let codAux = '';
      for (var i = 0; i < this.productAux.tallas.length; i++) {
        codAux = this.getCodigoEan(this.productAux.tallas[i]);
        if (codAux !== '') {
          this.codigosEan.push(codAux);
        }
      }
      let arrTiendaUids = this.tiendas.map((tienda) => tienda.msopuid);
      ticketApiService
        .getStockReservadoByEansOps(this.codigosEan, arrTiendaUids.join(','))
        .then((data) => {
          this.stockReservado = data;
          // console.log(this.stockReservado)
          this.getMedidas();
        })
        .catch((error) => {
          console.log('ticketApiService - getStockReservadoByOps', error, 'ERROR');
          this.getMedidas();
        });
    },
    getFotos() {
      if (this.productAux.fotos) {
        this.productAux.fotos.forEach((element) => {
          if (element.porDefecto === 'S') {
            if (element.url && !this.isSys4Conjunto()) {
              this.fotos.unshift(element.url);
            } else {
              this.fotos.unshift('data:image/png;base64,' + element.imagenBytes);
            }
          } else {
            if (element.url && !this.isSys4Conjunto()) {
              this.fotos.push(element.url);
            } else {
              this.fotos.push('data:image/png;base64,' + element.imagenBytes);
            }
          }
        });
      }
    },
    getFotoColores(productAux) {
      let url = '';
      if (productAux.fotos) {
        for (var i = 0; i < productAux.fotos.length; i++) {
          if (productAux.fotos[i].porDefecto === 'S') {
            if (productAux.fotos[i].url) {
              url = productAux.fotos[i].url;
            } else {
              url = 'data:image/png;base64,' + productAux.fotos[i].imagenBytes;
            }
          } else {
            if (productAux.fotos[i].url) {
              if (url === '') {
                url = productAux.fotos[i].url;
              }
            }
          }
        }
      }
      if (url === '') {
        return require('@/assets/images/noimg.jpg');
      } else {
        return url;
      }
    },
    cambiarArticulo(producto) {
      this.productAux = producto;
      this.init();
    },
    getColores() {
      tiendaApiService
        .getColoresByModelo(this.productAux.modelo)
        .then((data) => {
          this.colores = data;
          this.getStockColores()
        })
        .catch((error) => {
          console.log('tiendaApiService - getColoresByModelo ' + error);
        });
    },
    getMedidas() {
      tiendaApiService
        .getMedidasByIdArtic(this.productAux.id)
        .then((data) => {
          if (data !== '' && data !== null) {
            this.medidas = data;
            this.crearMedida();
          }
          this.getStock();
        })
        .catch((error) => {
          console.log('tiendaApiService - getMedidasByIdArtic ' + error);
          this.getStock();
        });
    },
    crearMedida() {
      let medidaAux = this.medidas.find((medida) => medida.tallaInterna === this.tallaInterna);
      if (medidaAux) {
        if (medidaAux.ancho && medidaAux.alto) {
          this.medida = '(' + medidaAux.ancho + ' x ' + medidaAux.alto;
          if (medidaAux.fondo) {
            this.medida = this.medida + ' x ' + medidaAux.fondo;
          }
          this.medida = this.medida + ' cm)';
        }
      }
    },
    // restar () {
    //   if (this.cantidad > 1) {
    //     this.cantidad -= 1
    //   }
    // },
    // sumar () {
    //   this.cantidad += 1
    // },
    addToCart() {
      if (this.precio !== '' && this.codigoEanActual !== '' && this.talla !== '') {
        this.error = false;
        for (var i = 0; i < this.productAux.tallas.length; i++) {
          if (this.productAux.tallas[i].tallaInterna === this.tallaInterna) {
            if (this.cantidad > this.total[i].cantidad) {
              this.error = true;
              this.snack = true;
              this.snackColor = 'red darken-2';
              this.snackText = 'No hay suficiente stock';
            } else {
              Object.assign(this.productAux, { stock: this.total[i].cantidad });
            }
            break;
          }
        }
        if (!this.error) {
          Object.assign(this.productAux, { cantidad: this.cantidad });
          Object.assign(this.productAux, { codigo: this.codigoEanActual });
          Object.assign(this.productAux, { precio: this.precio });
          Object.assign(this.productAux, { talla: this.talla });
          Object.assign(this.productAux, { foto: this.fotos[0] });
          this.$store.commit('addToCart', this.productAux);
          this.snack = true;
          this.snackColor = 'green';
          this.snackText = 'Se ha añadido correctamente';
        }
      } else {
        this.error = true;
        this.snack = true;
        this.snackColor = 'red darken-2';
        this.snackText = 'Ha ocurrido un error';
        console.log(this.codigoEanActual + ' ' + this.precio + ' ' + this.talla);
      }
    },
    getLikeList() {
      this.likeList = this.$store.getters.getLikeList;
      const r = this.likeList.some((producto) => producto.idArtic == this.productAux.id);
      this.isInLikeList = r;
    },
    addToLikeList() {
      if (!this.isInLikeList) {
        tiendaApiService
          .addToLikeList(this.$store.getters.getCliente.id, this.productAux.id)
          .then((data) => {
            if (data) {
              this.$store.commit('addToLikeList', this.productAux.id);
              this.isInLikeList = true;
            } else {
              this.snack = true;
              this.snackColor = 'red darken-2';
              this.snackText = 'Ha ocurrido un error';
            }
          })
          .catch((error) => {
            this.snack = true;
            this.snackColor = 'red darken-2';
            this.snackText = 'Ha ocurrido un error';
            console.log(error);
          });
      } else {
        tiendaApiService
          .deleteFromLikeList(this.$store.getters.getCliente.id, this.productAux.id)
          .then((data) => {
            if (data) {
              this.$store.commit('deleteFromLikeList', this.productAux.id);
              this.isInLikeList = false;
            } else {
              this.snack = true;
              this.snackColor = 'red darken-2';
              this.snackText = 'Ha ocurrido un error';
            }
          })
          .catch((error) => {
            this.snack = true;
            this.snackColor = 'red darken-2';
            this.snackText = 'Ha ocurrido un error';
            console.log(error);
          });
      }
    },
    addToHistorial() {
      tiendaApiService
        .addToHistorial(this.$store.getters.getCliente.id, this.productAux.id)
        .then((data) => {
          if (!data) {
            // alert('Ha ocurrido un error');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    moverStock(talla, unidades) {
      if (this.store1 == this.store2) {
        this.snack = true
        this.snackColor = 'red darken-2'
        this.snackText = 'Stock de la misma tienda'
      } else if (this.store1 !== this.opUid && this.store2 !== this.opUid) {  
        this.snack = true
        this.snackColor = 'red darken-2'
        this.snackText = 'Una tienda debe ser la propia'
       } else { 
        let movStock = []
        console.log(unidades)
        movStock.push(this.store1)
        movStock.push(this.store2)
        let tallaCompleta = this.productAux.tallas.find(element => element.talla == talla.value)
        console.log(tallaCompleta)
        if (tallaCompleta) {
          let ean = this.getCodigoEan(tallaCompleta)
          console.log(ean)
          movStock.push({
                        tallaInterna: tallaCompleta.tallaInterna,
                        tallaExterna: tallaCompleta.talla,
                        unidades: this.unidadesMov,
                        ean: ean,
                        idArtic: this.productAux.id
                        })
          tiendaApiService.movimientoStock(movStock)
            .then(() => {
              this.snack = true;
              this.snackColor = 'success';
              this.snackText = 'Stock solicitado';
            })
            .catch(error => {
              this.snack = true
              this.snackColor = 'red darken-2'
              this.snackText = 'Error al enviar el inventarío'
              console.log('tiendaApiService - enviarInventario ' + error)
            })
          }
       }
    },
    selecStock(item, unidades) {
      this.unidadesMov = unidades
      if (item.store === this.tiendas[0].msopclicode) {
        //la tienda que manda es la nuestra
        this.store1 = this.tiendas[0].msopuid
        if (this.tiendas.length > 1) {
          this.store2 = this.tiendas[1].msopuid
        }
        this.store1RO = true
        this.store2RO = false
      } else {
        //solicitamos stock a otra tienda
        this.store1RO = false
        this.store2RO = true
        this.store2 = this.tiendas[0].msopuid
        this.store1 = this.tiendas.find(element => element.msopclicode == item.store).msopuid
      }
    },
    cancel() {
      this.snack = true;
      this.snackColor = '';
      this.snackText = 'Pedido de stock cancelado';
    },
    errorStock() {
      this.snack = true;
      this.snackColor = 'red darken-2';
      this.snackText = 'Error, comprueba que el stock sea valido';
    },
    close() {
      console.log('Dialog closed');
    },
  },
});
</script>
